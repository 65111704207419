import { call } from "redux-saga/effects";
import networkInterCeptors from "./networkInterceptor";

export function* apicall(apiFunc, args = {}) {
  try {
    const network = apiFunc(args);
    const response = yield call(networkInterCeptors, network);
    return { response: response?.data, status: response?.status };
  } catch (error) {
    throw error;
  }
}
