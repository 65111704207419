import React, { useCallback } from "react";
import Button from "./Button";

function DraweComponent({ isVisible, setVisible, children }) {
  const closeDrawer = useCallback(() => {
    setVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="fixed top-0 bottom-0 right-0 bg-white w-full min-w-[200px] md:w-auto md:min-w-[450px] shadow-lg p-5 flex flex-col z-50">
      <div className="flex justify-between items-center mb-5">
        <h6 className="text-lg font-medium">Apply Filters</h6>
        <Button
          styleNone={true}
          iconName={"close"}
          handlePress={closeDrawer}
          iconBtn={true}
        />
      </div>
      <div className="flex-1 flex flex-col">{children}</div>
    </div>
  );
}

export default React.memo(DraweComponent);
