import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { getProductAction } from "../reduxSetup/productAction";

function AdminDashBoard() {
  const dispatch = useDispatch();

  const handleGoogleSuccess = async (credentialResponse) => {
    const token = credentialResponse.credential;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/auth/google`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        }
      );
      if (response.status !== 200) {
        const data = await response.json();

        dispatch(
          getProductAction(
            { isAuthenticated: false, token: null },
            "GET_USER_DETAILS"
          )
        );
      } else {
        dispatch(
          getProductAction(
            { isAuthenticated: true, token: token },
            "GET_USER_DETAILS"
          )
        );
      }
    } catch (err) {
      getProductAction(
        { isAuthenticated: false, token: null },
        "GET_USER_DETAILS"
      );
    }
  };

  const handleGoogleFailure = (error) => {
    console.error("Google Login Failure:", error);
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="p-8 flex justify-center items-center h-screen">
        <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold text-gray-700 text-center mb-6">
            Login with Google
          </h2>
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleFailure}
          />
        </div>
      </div>
    </GoogleOAuthProvider>
  );
}

export default React.memo(AdminDashBoard);
