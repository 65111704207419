import React from "react";
import img from "../assests/images/loader.gif";

function Loader({ size = "200px" }) {
  return (
    <div>
      <img
        alt="list-img"
        className={`w-[${size}] h-[${size}] object-contain`}
        src={img}
      />
    </div>
  );
}

export default Loader;
