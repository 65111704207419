import React, { useState, useMemo, useCallback } from "react";
import { tableSelectConstants } from "../utils/ProductConstants";
import {
  isArrayNonEmpty,
  isValidString,
  safeText,
} from "../utils/productHelper";
import Pagination from "./PaginationBtns";
import Loader from "./Loader";
function TableComponent({
  data,
  headerDetails,
  collapseable = false,
  renderCollapse,
  loader = false,
  customFooterItem,
}) {
  const [isCollapsed, setIsCollapsed] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const tatlaPages = useMemo(() => {
    return Math?.ceil(data?.length / rowsPerPage);
  }, [data, rowsPerPage]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const currentPageData = useMemo(() => {
    return data?.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
  }, [currentPage, rowsPerPage, data]);

  const handleRowChange = useCallback((e) => {
    if (isValidString(e?.target?.value)) {
      setCurrentPage(1);
      setRowsPerPage(e?.target?.value);
    }
  }, []);

  const renderTableHeader = useMemo(() => {
    return (
      <div
        className={`p-2 flex gap-2 min-w-[800px] lg:min-w-fit bg-white border-b-2 xl:pr-7 rounded-t-lg  `}
      >
        {collapseable && <div className={`w-[40px]`} />}
        {headerDetails?.map((item, index) => {
          return (
            <div key={index} className={`${item?.flexWidthClass}`}>
              <label className="text-sm text-slate-500">{item?.Title}</label>
            </div>
          );
        })}
      </div>
    );
  }, [collapseable, headerDetails]);

  const renderFooter = useMemo(() => {
    return (
      <div className="p-3 w-full  gap-3 lg:gap-0 border-t-2 flex flex-col  md:flex-row justify-between items-center">
        <div className="flex flex-col w-full  sm:flex-row  items-center gap-5 mb-5 md:mb-0 ">
          <div className="flex  w-full md:w-auto flex-row justify-between md:justify-start gap-3">
            <div className="flex flex-row  items-center ">
              <label className="text-sm font-medium text-gray-700 mr-2">
                Total item:
              </label>
              <p className="text-sm font-medium text-black">{data?.length}</p>
            </div>
            <div>
              <label
                htmlFor="rows-per-page"
                className="text-sm font-medium text-gray-700 mr-2"
              >
                Rows per page:
              </label>
              <select
                id="rows-per-page"
                value={rowsPerPage}
                onChange={handleRowChange}
                className="px-3 py-2 border border-gray-300 rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                {tableSelectConstants?.map((item, index) => {
                  return (
                    <option key={index} onChange={null} value={item?.value}>
                      {item?.value}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {customFooterItem && customFooterItem}
        </div>
        <div>
          <Pagination
            onPageChange={handlePageChange}
            currentPage={currentPage}
            totalPages={tatlaPages}
          />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, rowsPerPage, tatlaPages, currentPage, customFooterItem]);

  const handleCollapse = useCallback(
    (id) => {
      if (isCollapsed === id) {
        setIsCollapsed(null);
      } else {
        setIsCollapsed(id);
      }
    },
    [isCollapsed]
  );

  const renderTableContent = useMemo(() => {
    return isArrayNonEmpty(currentPageData) ? (
      currentPageData?.map((item, index) => {
        return (
          <div key={item?.id} className="border-b-2 min-w-[800px] lg:min-w-fit">
            <div key={index} className="p-2 flex gap-2 items-center">
              {collapseable && (
                <div className={`w-[40px]`}>
                  <button onClick={() => handleCollapse(item?.id)}>
                    <span
                      className={`material-symbols-outlined text-slate-500  ${
                        isCollapsed === item?.id ? "rotate-180" : "rotate-0"
                      } ease-in duration-300`}
                    >
                      keyboard_arrow_down
                    </span>
                  </button>
                </div>
              )}
              {headerDetails?.map((ValueItem, indexValue) => {
                return (
                  <div
                    key={indexValue}
                    className={`${ValueItem?.flexWidthClass}`}
                  >
                    {ValueItem?.customRender ? (
                      ValueItem.renderItem && ValueItem.renderItem(item)
                    ) : (
                      <p className="text-sm text-black">
                        {safeText(item[`${ValueItem?.keyToMap}`])}
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
            {collapseable && isCollapsed === item?.id && (
              <div className="p-2 pt-0">
                <div className="p-3 border-2 rounded-lg">
                  {renderCollapse && renderCollapse(item)}
                </div>
              </div>
            )}
          </div>
        );
      })
    ) : (
      <div className="flex justify-center items-center h-full">
        {loader ? <Loader size="80px" /> : <h6>No Data Available</h6>}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageData, collapseable, headerDetails, isCollapsed, loader]);

  return (
    <div className="w-full flex flex-1 flex-col justify-between bg-white rounded-lg border-2">
      <div className="flex-1 flex flex-col ">
        <div className="bg-white rounded-t-lg overflow-x-auto sticky top-0 z-20  ">
          {renderTableHeader}
        </div>
        <div className="overflow-y-none flex-1  xl:overflow-y-scroll max-h-none bg-white overflow-x-auto xl:max-h-[480px] ">
          {renderTableContent}
        </div>
      </div>
      <div className="flex-none bg-white rounded-b-lg z-20 sticky lg:static bottom-0  ">
        {renderFooter}
      </div>
    </div>
  );
}

export default React.memo(TableComponent);
