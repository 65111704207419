import React, { useState } from "react";

const VehicleImageCarousel = ({ images }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);

  const handleImageChange = (direction) => {
    const totalImages = images?.length || 0;
    if (direction === "next") {
      setImageIndex((prevIndex) => (prevIndex + 1) % totalImages);
    } else {
      setImageIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
    }
  };

  if (!images?.length) return null;

  return (
    <div className="flex flex-col items-center">
      <div className="relative">
        <img
          className="w-[90px] h-[60px] object-cover rounded-lg cursor-pointer"
          alt="vehicle thumb"
          src={images[imageIndex]}
          onClick={() => setIsZoomed(true)}
        />
        {/* Image Navigation */}
        {images?.length > 1 && (
          <div className="absolute top-0 left-0 right-0 flex justify-between items-center px-2 py-1">
            <button
              onClick={() => handleImageChange("prev")}
              className="bg-black text-white p-1 rounded-full"
            >
              &lt;
            </button>
            <button
              onClick={() => handleImageChange("next")}
              className="bg-black text-white p-1 rounded-full"
            >
              &gt;
            </button>
          </div>
        )}
      </div>

      {/* Zoom Modal */}
      {isZoomed && (
        <div
          className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center p-4"
          style={{ top: "50px", bottom: "50px", zIndex: 1000 }}
        >
          {images?.length > 1 && (
            <button
              onClick={() => handleImageChange("prev")}
              className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white text-black p-2 rounded-full"
            >
              &lt;
            </button>
          )}
          <img
            className="max-w-full max-h-full object-contain"
            alt="zoomed vehicle"
            src={images[imageIndex]}
          />
          {images?.length > 1 && (
            <button
              onClick={() => handleImageChange("next")}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white text-black p-2 rounded-full"
            >
              &gt;
            </button>
          )}
          <button
            onClick={() => setIsZoomed(false)}
            className="absolute top-4 right-4 bg-white text-black p-2 rounded-full"
          >
            ✕
          </button>
        </div>
      )}
    </div>
  );
};

export default VehicleImageCarousel;
