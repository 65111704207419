import React from "react";

const ToggleSwitch = ({ isOn, onToggle, disabled }) => {
  return (
    <button
      disabled={disabled}
      type="button"
      className={`relative inline-flex items-center h-6 w-11 rounded-full transition-colors duration-300 ${
        isOn ? "bg-indigo-500" : "bg-gray-300"
      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
      aria-pressed={isOn}
      onClick={onToggle}
    >
      <span
        className={`inline-block h-4 w-4 transform rounded-full bg-white shadow-lg transition-transform duration-300 ${
          isOn ? "translate-x-6" : "translate-x-1"
        }`}
      />
    </button>
  );
};

export default React.memo(ToggleSwitch);
