import React, { useCallback } from "react";
import { isValidElement } from "../utils/productHelper";

function RangeFilter({ rangeFilter, setRangeFilter }) {
  const hadleValueChange = useCallback((e, key) => {
    const value = e?.target?.value;

    if (isValidElement(value)) {
      setRangeFilter((prevValues) => {
        return {
          ...prevValues,
          [key]: value,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="max-w-lg mx-auto ">
      <h2 className="text-lg font-semibold text-gray-700 mb-3">
        Price Range Filter
      </h2>

      <div className="flex items-center justify-between space-x-4 mb-5">
        <div>
          <label className="block text-sm text-gray-500 mb-2">Min Price</label>
          <input
            type="number"
            id="minPrice"
            className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
            value={rangeFilter?.minValue}
            onChange={(e) => hadleValueChange(e, "minValue")}
            min="0"
          />
        </div>

        <div>
          <label className="block text-sm text-gray-500 mb-2">Max Price</label>
          <input
            type="number"
            id="maxPrice"
            className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
            value={rangeFilter?.maxValue}
            onChange={(e) => hadleValueChange(e, "maxValue")}
            min="0"
          />
        </div>
      </div>
    </div>
  );
}

export default RangeFilter;
