export const isValidElement = (data) => {
  return data !== null && data !== undefined;
};

export const isValidString = (text) => {
  return isValidElement(text) && text !== "";
};

export const isArrayNonEmpty = (data) => {
  return isValidElement(data) && Array.isArray(data) && data.length > 0;
};

export const getValidData = (data) => {
  if (isArrayNonEmpty(data)) {
    return data?.filter((item) => {
      return isValidElement(item);
    });
  }
};

export const safeText = (text) => {
  return isValidString(text) ? text : "N/A";
};

export const filterAvailableProducts = (data) => {
  if (isArrayNonEmpty(data)) {
    return data?.filter((item) => {
      return !isValidElement(item?.soldDetails);
    });
  }
};

export const getEnquiryWithProduct = (enquiryData, productListData) => {
  let resposeData = [];
  if (isArrayNonEmpty(enquiryData)) {
    resposeData = enquiryData;
    if (isArrayNonEmpty(productListData)) {
      resposeData = enquiryData.map((item) => {
        const matchingProduct = productListData.find(
          (product) => product.id === item?.vehicleId
        );

        if (matchingProduct) {
          item.VehicleDetails = {
            ...matchingProduct,
          };
        }

        return item;
      });
    }
  }

  return resposeData;
};
