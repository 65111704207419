import React, { useCallback, useEffect, useMemo, useState } from "react";
import { footerItems } from "../utils/ProductConstants";
import ListComponent from "../components/ListComponent";
import Modal from "../components/Modal";
import Button from "../components/Button";
import InputComponet from "../components/InputComponet";
import PaginationBtns from "../components/PaginationBtns";
import {
  isValidElement,
  isArrayNonEmpty,
  isValidString,
} from "../utils/productHelper";
import {
  getProductAction,
  productReducerAction,
} from "../reduxSetup/productAction";
import { useSelector, useDispatch } from "react-redux";
import { productListData, enquiryList } from "../reduxSetup/productSelector";
import Loader from "../components/Loader";
import DraweComponent from "../components/DraweComponent";
import FilterSearch from "../components/FilterSearch";
import RangeFilter from "../components/RangeFilter";

function ProductListScreen() {
  const [modalVisible, setModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [submitBtnDisabled, setSubitBtnDisabled] = useState(false);
  const [enquiryData, setEnquiryData] = useState({
    name: "",
    mobileNumber: "",
    message: "",
  });
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [rangeFilter, setRangeFilter] = useState({
    minValue: "",
    maxValue: "",
  });
  const itemsPerPage = 10;

  const dispatch = useDispatch();

  const { AvailableProducts, productLoading } = useSelector(productListData);
  const { isCloseEnquiryModal } = useSelector(enquiryList);

  useEffect(() => {
    if (isCloseEnquiryModal) {
      closeModal();
      dispatch(productReducerAction({ isCloseEnquiryModal: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCloseEnquiryModal]);

  useEffect(() => {
    dispatch(productReducerAction({ isCloseEnquiryModal: false }));
    dispatch(getProductAction(null, "GET_PRODUCT_LIST"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filetredItems = useMemo(() => {
    let filterdItems = [];
    if (isArrayNonEmpty(AvailableProducts)) {
      filterdItems = AvailableProducts;

      if (isArrayNonEmpty(selectedCategories)) {
        filterdItems = AvailableProducts?.filter((item) =>
          selectedCategories?.includes(item?.id)
        );
      }
      if (
        isValidString(rangeFilter?.minValue) ||
        isValidString(rangeFilter?.maxValue)
      ) {
        const min = Number(rangeFilter.minValue) ||  -Infinity;
        const max = Number(rangeFilter.maxValue) ||  Infinity;

        filterdItems = filterdItems.filter(
          (item) => Number(item?.price) >= min && Number(item?.price) <= max
        );
      }
    }

    return filterdItems;
  }, [selectedCategories, AvailableProducts, rangeFilter]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const totalPages = useMemo(() => {
    return Math?.ceil(filetredItems?.length / itemsPerPage);
  }, [filetredItems]);

  const currentPageData = useMemo(() => {
    return filetredItems?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [currentPage, filetredItems]);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setSelectedProduct(null);
    setEnquiryData({
      name: "",
      mobileNumber: "",
      message: "",
    });
    // eslint-disable-next-line
  }, []);

  const handleSendEnquiry = useCallback(() => {
    const payload = {
      ...enquiryData,
      vehicleId: isValidString(selectedProduct?.id) ? selectedProduct?.id : "",
    };

    dispatch(getProductAction(payload, "ADD_ENQUIRY_DATA"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enquiryData, selectedProduct]);

  const handleInputChange = useCallback((e, key) => {
    const value = e?.target?.value;
    if (isValidElement(value)) {
      setEnquiryData((prevCredentials) => {
        const updatedCredentials = { ...prevCredentials, [key]: value };
        const areBothValid =
          updatedCredentials?.name?.length >=2 &&
          updatedCredentials?.mobileNumber?.length === 10;

        setSubitBtnDisabled(areBothValid);

        return updatedCredentials;
      });
    }
  }, []);

  const renderModal = useMemo(() => {
    return (
      <Modal
        styles="w-full max-w-[500px]"
        closeModal={closeModal}
        isOpen={modalVisible}
      >
        <div className="p-5 w-full">
          <div className="flex-row flex justify-between items-center mb-3">
            <div className="mb-1 flex flex-row items-center gap-2">
              <h3 className="font-medium text-xl ">
                {selectedProduct?.model} :
              </h3>
              <div className="flex items-center">
                <span className="material-symbols-outlined text-sm">
                  currency_rupee
                </span>
                <p className="font-medium text-sm text-slate-500 ">
                  {selectedProduct?.price}
                </p>
              </div>
            </div>

            <Button
              styleNone={true}
              iconName={"close"}
              handlePress={closeModal}
              iconBtn={true}
            />
          </div>

          <div className="w-full mb-3">
            <InputComponet
              mandatory={true}
              value={enquiryData?.name}
              onChange={(e) => handleInputChange(e, "name")}
              placeholder="Enter your name"
              type="text"
              label="Name"
            />
          </div>
          <div className="w-full mb-3">
            <InputComponet
              mandatory={true}
              value={enquiryData?.mobileNumber}
              onChange={(e) => handleInputChange(e, "mobileNumber")}
              placeholder="Enter your Phone Number"
              type="number"
              label="Phone Number"
              minValue="10"
            />
          </div>

          <div className="w-full mb-3">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Message
            </label>
            <textarea
              value={enquiryData?.message}
              onChange={(e) => handleInputChange(e, "message")}
              id="message"
              placeholder="Message"
              className="w-full h-20 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
            ></textarea>
          </div>
          <div className="mt-3 flex justify-end">
            <Button
              disabled={!submitBtnDisabled}
              label={"Send enquiry"}
              styleClass="w-[160px] py-2 px-4"
              handlePress={handleSendEnquiry}
            />
          </div>
        </div>
      </Modal>
    );
    // eslint-disable-next-line
  }, [modalVisible, selectedProduct, enquiryData, submitBtnDisabled]);

  const renderFooter = useMemo(() => {
    return (
      <div className="py-0 md:py-3 lg:py-5 bg-white  grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 border-t-2 md:gap-3 lg:gap-0  ">
        {footerItems?.map((item) => {
          return (
            <div
              key={item?.label}
              className="border-b-2 md:border-b-0 py-2 md:py-0 lg:mb-0  flex items-center gap-2 border-r-0 md:border-r-2   px-2 lg:px-5"
            >
              <span className="text-[22px] lg:text-[28px] font-light text-indigo-500 material-symbols-outlined">
                {item?.icon}
              </span>
              <div>
                <label className="text-xs lg:text-sm font-medium text-gray-700 mb-1">
                  {item?.label}
                </label>
                <p className="text-[10px] lg:text-[13px] font-medium text-slate-500	 mb-1">
                  {item?.value}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, []);

  const handleModalChange = useCallback((item) => {
    setModalVisible(true);
    setSelectedProduct(item);
  }, []);

  const renderList = useMemo(() => {
    return isArrayNonEmpty(currentPageData) ? (
      <div className="grid p-5 flex-1  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4    gap-4">
        {currentPageData?.map((item) => {
          return (
            <ListComponent
              onItemPress={() => handleModalChange(item)}
              key={item.id}
              listData={item}
            />
          );
        })}
      </div>
    ) : (
      <div className="flex-1 flex flex-row justify-center items-center p-5">
        {productLoading ? <Loader /> : <h6>No Data Available</h6>}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageData, productLoading]);

  const handleFilterOpen = useCallback(() => {
    setFilterDrawer(!filterDrawer);
  }, [filterDrawer]);

  const renderFilterIconBtn = useMemo(() => {
    return (
      <button
        onClick={handleFilterOpen}
        className="fixed w-[60px] h-[60px] bottom-[100px] left-20 bg-regal-blue rounded-full border-2 flex justify-center items-center"
      >
        <span className="material-symbols-outlined text-white">
          filter_list
        </span>
      </button>
    );
  }, [handleFilterOpen]);

  const clearFilter = useCallback(() => {
    setSelectedCategories([]);
    setFilterDrawer(false);
    setRangeFilter({
      minValue: "",
      maxValue: "",
    });
  }, []);

  const isFilterAcive = useMemo(() => {
    return (
      isArrayNonEmpty(selectedCategories) ||
      (isValidString(rangeFilter?.minValue) ||
        isValidString(rangeFilter?.maxValue))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeFilter, selectedCategories]);

  const renderFilterDrawer = useMemo(() => {
    return (
      <DraweComponent setVisible={setFilterDrawer} isVisible={filterDrawer}>
        <div className="flex flex-col justify-between items-between flex-1">
          <FilterSearch
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            filteredData={AvailableProducts}
          />
          <RangeFilter
            rangeFilter={rangeFilter}
            setRangeFilter={setRangeFilter}
          />
          <div className="sticky bottom-0 absolute right-0 p-3">
            <Button
              disabled={!isFilterAcive}
              label={"Clear Filter"}
              styleClass="w-[160px] py-2 px-4"
              handlePress={clearFilter}
            />
          </div>
        </div>
      </DraweComponent>
    );
  }, [
    filterDrawer,
    selectedCategories,
    AvailableProducts,
    rangeFilter,
    clearFilter,
    isFilterAcive,
  ]);

  return (
    <div className="flex flex-1 flex-col">
      <div className="px-5 py-3 border-b-2 sticky top-0 left-0 right-0 bg-white z-10 shadow-md flex justify-between items-center flex-col md:flex-row">
        <div className="mb-2 md:mb-0">
          <h3 className="font-medium sm:grid-cols-1 md:grid-cols-2 lg:text-xl xl:text-3xl uppercase">
            Sri Balaji Auto Consultant
          </h3>
        </div>
        <div>
          <PaginationBtns
            onPageChange={handlePageChange}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        </div>
      </div>
      {renderList}
      {renderFooter}
      {renderFilterIconBtn}
      {filterDrawer && renderFilterDrawer}
      {isValidElement(selectedProduct) && renderModal}
    </div>
  );
}

export default React.memo(ProductListScreen);
