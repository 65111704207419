import { useCallback, useMemo } from "react";
import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const maxPagesToShow = useMemo(() => {
    return window.innerWidth > 500 ? 5 : 2;
  }, []);
  const startPage = useMemo(() => {
    return Math.floor((currentPage - 1) / maxPagesToShow) * maxPagesToShow + 1;
  }, [currentPage, maxPagesToShow]);

  const endPage = useMemo(() => {
    return Math.min(startPage + maxPagesToShow - 1, totalPages);
  }, [startPage, maxPagesToShow, totalPages]);

  const handlePageChange = useCallback(
    (page) => {
      if (page > 0 && page <= totalPages) {
        onPageChange(page);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalPages]
  );
  return (
    <div className="flex items-center justify-center space-x-2">
      <button
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
        className={`px-2 py-2 border text-xs sm:text-[15px] border-gray-300 rounded-lg ${
          currentPage === 1
            ? "text-gray-400 cursor-not-allowed"
            : "text-gray-700 hover:bg-gray-100"
        }`}
      >
        {"<<"}
      </button>

      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`px-2 py-2 border border-gray-300 text-xs sm:text-[15px] rounded-lg ${
          currentPage === 1
            ? "text-gray-400 cursor-not-allowed"
            : "text-gray-700 hover:bg-gray-100"
        }`}
      >
        Previous
      </button>

      {Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      ).map((page) => (
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={`px-3 py-2 border border-gray-300 text-xs sm:text-[15px] rounded-lg ${
            currentPage === page
              ? "bg-regal-blue text-white"
              : "text-gray-700 hover:bg-gray-100"
          }`}
        >
          {page}
        </button>
      ))}

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`px-2 py-2 border border-gray-300 text-xs sm:text-[15px]  rounded-lg ${
          currentPage === totalPages
            ? "text-gray-400 cursor-not-allowed"
            : "text-gray-700 hover:bg-gray-100"
        }`}
      >
        Next
      </button>

      <button
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
        className={`px-3 py-2 text-xs sm:text-[15px] border border-gray-300 rounded-lg ${
          currentPage === totalPages
            ? "text-gray-400 cursor-not-allowed"
            : "text-gray-700 hover:bg-gray-100"
        }`}
      >
        {">>"}
      </button>
    </div>
  );
};

export default React.memo(Pagination);
