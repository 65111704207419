import React, { useMemo, useEffect, useCallback } from "react";
import TableComponent from "../components/TableComponent";
import { useDispatch, useSelector } from "react-redux";
import { getProductAction } from "../reduxSetup/productAction";
import { enquiryList } from "../reduxSetup/productSelector";
import {
  isValidString,
  safeText,
  isValidElement,
} from "../utils/productHelper";
import img from "../assests/images/emptyImg.jpg";
import { userLoggedInnSelector } from "../reduxSetup/productSelector";
import VehicleImageCarousel from "./VehicleImageCarousel";

function EnquiryListScreen() {
  const dispatch = useDispatch();
  const { authorization } = useSelector(userLoggedInnSelector);
  useEffect(() => {
    dispatch(
      getProductAction({ authorization: authorization }, "GET_ENQUIRY_LIST")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { enquires, isEnquiryListLoading } = useSelector(enquiryList);

  const HeaderConstants = useMemo(() => {
    return [
      {
        Title: "Registration Number",
        flexWidthClass: "flex-1",
        customRender: true,
        keyToMap: "registrationNumber",
        renderItem: (item) => (
          <h5 className="text-sm text-black">{safeText(item?.VehicleDetails?.registrationNumber)}</h5>
        ),
      },
      {
        Title: "Mobile Number",
        flexWidthClass: "flex-1",
        customRender: true,
        keyToMap: "mobileNumber",
        renderItem: (value) => (
          <p className="text-sm text-slate-500">
            {safeText(value?.mobileNumber)}
          </p>
        ),
      },
      {
        Title: "Name",
        flexWidthClass: "flex-1",
        customRender: true,
        keyToMap: "name",
        renderItem: (value) => (
          <h5 className="text-sm text-black">{safeText(value?.name)}</h5>
        ),
      },
      {
        Title: "Message",
        flexWidthClass: "flex-1",
        customRender: true,
        keyToMap: "message",
        renderItem: (value) => (
          <p className="text-xs text-slate-500">{safeText(value?.message)}</p>
        ),
      },
    ];
  }, []);

  const renderCollapse = useCallback((item) => {
    return (
      <div>
        {isValidElement(item?.VehicleDetails) ? (
          <div className="flex gap-5 items-center">
            <VehicleImageCarousel
              images={item?.VehicleDetails?.vehicleImages}
            />

            <div>
              <div className="flex gap-2 items-center mb-2">
                <label className="text-sm font-medium">Vehicle Name :</label>
                <p className="text-sm font-regular text-slate-500 ">
                  {safeText(item?.VehicleDetails?.model)}
                </p>
              </div>
              <div className="flex gap-2 items-center mb-2">
                <label className="text-sm font-medium">
                  Registration Naumber :
                </label>
                <p className="text-sm font-regular text-slate-500 ">
                  {safeText(item?.VehicleDetails?.registrationNumber)}
                </p>
              </div>
              <div className="flex gap-2 items-center mb-2">
                <label className="text-sm font-medium">Price :</label>
                <p className="text-sm font-regular text-slate-500 flex items-center ">
                  <span className="material-symbols-outlined text-sm">
                    currency_rupee
                  </span>
                  {safeText(item?.VehicleDetails?.price)}
                </p>
              </div>
              <div className="flex gap-2 items-center mb-2">
                <label className="text-sm font-medium">Make :</label>
                <p className="text-sm font-regular text-slate-500 flex items-center ">
                  {safeText(item?.VehicleDetails?.make)}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-row items-center justify-center">
            <h6 className="text-sm font-medium">No Details Available</h6>
          </div>
        )}
      </div>
    );
  }, []);

  const renderTableComponent = useMemo(() => {
    return (
      <TableComponent
        collapseable={true}
        renderCollapse={(item) => renderCollapse(item)}
        loader={isEnquiryListLoading}
        headerDetails={HeaderConstants}
        data={enquires}
      />
    );
  }, [HeaderConstants, enquires, isEnquiryListLoading, renderCollapse]);
  return (
    <div className="p-0 sm:p-3 lg:p-10 flex flex-col max-w-screen-lg w-full h-full m-auto">
      <div className="grow-0 p-3 sm:p-0">
        <h3 className="font-medium text-2xl py-2">Enquiry</h3>
      </div>
      <div className="grow flex flex-col">{renderTableComponent}</div>
    </div>
  );
}

export default React.memo(EnquiryListScreen);
