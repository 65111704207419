import React, { useCallback, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import AddEditFrame from "./AddEditFrame";
import { useDispatch, useSelector } from "react-redux";
import { productListData } from "../reduxSetup/productSelector";
import { productReducerAction } from "../reduxSetup/productAction";

function AddProductScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const childRef = useRef();
  const [buttonActive, setButtonActive] = useState(true);

  const { isAddProductSuccess } = useSelector(productListData);

  useEffect(() => {
    if (isAddProductSuccess) {
      navigate("/ManageProducts");
      dispatch(productReducerAction({ isAddProductSuccess: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddProductSuccess]);

  const handleFormSubmit = useCallback(() => {
    if (childRef?.current) {
      childRef?.current?.formSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-2 lg:p-10 flex flex-col max-w-[900px] w-full  m-auto">
      <div className="flex flex-row justify-start items-center mb-4">
        <Button
          styleNone={true}
          iconName={"arrow_back"}
          handlePress={() => navigate("/ManageProducts")}
          iconBtn={true}
        />

        <h3 className="font-medium text-2xl ml-5 ">Add Product</h3>
      </div>
      <div className="w-full flex flex-col items-between  bg-white flex-1 rounded-lg border-2 ">
        <div className="overflow-y-auto lg:max-h-[500px]  p-5  flex-1">
          <AddEditFrame ref={childRef} setButtonActive={setButtonActive} />
        </div>

        <div className="flex justify-end border-t-2  p-5">
          <Button
            disabled={buttonActive}
            handlePress={handleFormSubmit}
            styleClass="w-[160px] py-2 px-4"
            label="Add Product"
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(AddProductScreen);
