import React from "react";

function InputComponet({
  label,
  type,
  placeholder,
  maxLength,
  onChange,
  value,
  mandatory,
}) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">
        {label}
        {mandatory && <span className="text-red-500 ml-1">*</span>}
      </label>
      <div className="flex items-center">
        <input
          min={0}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          type={type}
          id={type}
          placeholder={placeholder}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>
    </div>
  );
}

export default React.memo(InputComponet);
