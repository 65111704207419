import { PRODUCT_TYPE } from "./productType";
import { createTransform } from "redux-persist";

export const authTokenTransform = createTransform(
  (inboundState) => {
    return { authToken: inboundState.authToken };
  },
  (outboundState) => outboundState
);

const initialState = {
  authToken: null,
  name: null,
  productList: [],
  productLoading: false,
  enquiryList: [],
  isEnquiryListLoading: false,
  isCloseEnquiryModal: false,
  isAddProductSuccess: false,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_TYPE.PRODUCT_REDUCER_TYPE:
      return { ...state, ...action.params };
    default:
      return state;
  }
};

export default productReducer;
