export const navigationConstants = [
  {
    iconName: "local_shipping",
    navigation: "/Home",
  },
  {
    iconName: "account_circle",
    navigation: "/ManageProducts",
  },
];

export const tableSelectConstants = [
  {
    value: 5,
  },
  {
    value: 10,
  },
  {
    value: 20,
  },

  {
    value: 50,
  },

  {
    value: 100,
  },
];

export const footerItems = [
  {
    id: 1,
    icon: "location_on",
    value: "No34, Nanbargal Nagar, poonamalle, Chennai-600 056",
    label: "Address",
  },

  {
    id: 2,
    icon: "mail",
    value: "harichandra2@gmail.com",
    label: "Mail Address",
  },

  {
    id: 3,
    icon: "chat",
    value: "91766 56224",
    label: "Whatsapp number",
  },

  {
    id: 4,
    icon: "phone_iphone",
    value: "94440 56224, 94450 95473, 7358171107",
    label: "Contact Number",
  },
];

export const soldDetails = [
  {
    label: "Customer Photo",
    inputType: "IMAGE",
    input_key: "customerPhoto",
    mandatory: false,
  },
  {
    label: "Adhar Photo",
    inputType: "IMAGE",
    input_key: "aadhaarPhoto",
    mandatory: false,
  },
  {
    label: "Customer Name",
    mandatory: true,
    minLength: 4,
    input_key: "name",
  },
  {
    label: "Mobile Number",
    mandatory: true,
    minLength: 10,
    input_key: "mobileNumber",
  },
  {
    label: "Date",
    mandatory: true,
    type: "date",
    minLength: 4,
    input_key: "date",
  },
  {
    label: "Chasis Number",
    mandatory: false,
    minLength: 4,
    type: "number",
    input_key: "chasisNumber",
  },

  {
    label: "Engine Number",
    mandatory: false,
    minLength: 4,
    input_key: "engineNumber",
    type: "number",
  },
];

export const inputData = [
  {
    label: "Vehicle Model",
    mandatory: true,
    input_key: "model",
    maxLength: 45,
    minLength: 2,
  },
  {
    label: "Price",
    mandatory: true,
    type: "number",
    input_key: "price",
    maxLength: 14,
    minLength: 2,
  },
  {
    label: "Registration Number",
    mandatory: true,
    input_key: "registrationNumber",
    maxLength: 30,
    minLength: 2,
  },
  {
    label: "Make",
    input_key: "make",
    mandatory: true,
    minLength: 2,
  },
  {
    label: "Emission Standard",
    input_key: "emissionStandard",
    maxLength: 20,
    minLength: 2,
  },
  {
    label: "Ownership",
    mandatory: false,
    input_key: "ownership",
    maxLength: 20,
    minLength: 4,
  },
  {
    label: "Km Driven",
    mandatory: false,
    type: "number",
    input_key: "kmDriven",
    maxLength: 10,
    minLength: 2,
  },
  {
    label: "Fc Number",
    mandatory: false,
    type: "string",
    input_key: "fc",
    maxLength: 20,
    minLength: 2,
  },
  {
    label: "RC Number",
    mandatory: false,
    type: "string",
    input_key: "rc",
    maxLength: 100,
    minLength: 2,
  },
  {
    label: "Insurance",
    mandatory: false,
    input_key: "insurance",
    maxLength: 10,
    minLength: 2,
  },
  {
    label: "Passing",
    mandatory: false,
    type: "number",
    input_key: "passing",
    maxLength: 15,
    minLength: 2,
  },
  {
    label: "Vehicle Feet",
    type: "number",
    input_key: "feet",
    maxLength: 10,
    minLength: 2,
  },
  {
    label: "Vehicle Body Type",
    input_key: "bodyType",
    maxLength: 20,
    minLength: 3,
  },
  {
    label: "Tax",
    type: "number",
    input_key: "tax",
    maxLength: 15,
    minLength: 2,
  },
  {
    label: "Vehicle Image",
    inputType: "IMAGE",
    input_key: "vehicleImages",
    mandatory: false,
  },
  {
    label: "Rc Book Image",
    inputType: "IMAGE",
    input_key: "rcBook",
    mandatory: false,
  },
];
