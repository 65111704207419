import React, {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import InputComponet from "../components/InputComponet";

import { isValidElement, isValidString } from "../utils/productHelper";
import { getProductAction } from "../reduxSetup/productAction";
import { useDispatch, useSelector } from "react-redux";
import { soldDetails, inputData } from "../utils/ProductConstants";
import ImageUploadComponent from "../components/ImageUploadComponent";
import { userLoggedInnSelector } from "../reduxSetup/productSelector";

const AddEditFrame = forwardRef(
  ({ isSold, setButtonActive, isEdit = false, editData = null }, ref) => {
    const dispatch = useDispatch();
    const { authorization } = useSelector(userLoggedInnSelector);

    const [soldData, setSoldData] = useState({
      name: "",
      date: "",
      aadhaarPhoto: [],
      chasisNumber: "",
      engineNumber: "",
      customerPhoto: [],
      mobileNumber: "",
    });

    const [inputField, setInputField] = useState({
      registrationNumber: "",
      emissionStandard: "",
      model: "",
      ownership: "",
      kmDriven: "",
      make: "",
      fc: "",
      insurance: "",
      passing: "",
      feet: "",
      bodyType: "",
      tax: "",
      price: "",
      vehicleImages: [],
      rcBook: [],
    });

    useEffect(() => {
      if (isEdit && isValidElement(editData)) {
        setInputField((prevState) => ({
          ...prevState,
          ...Object.keys(prevState).reduce((acc, key) => {
            if (editData?.hasOwnProperty(key)) {
              acc[key] = editData[key];
            }
            return acc;
          }, {}),
        }));

        if (isSold && isValidElement(editData?.soldDetails)) {
          setSoldData((prevState) => ({
            ...prevState,
            ...Object.keys(prevState).reduce((acc, key) => {
              if (editData?.soldDetails?.hasOwnProperty(key)) {
                acc[key] = editData?.soldDetails[key];
              }
              return acc;
            }, {}),
          }));
        }
      }
    }, [isEdit, editData, isSold]);

    const formSubmit = useCallback(() => {
      const formData = new FormData();

      let payloadData = inputField;

      if (isEdit && isSold && isValidElement(soldData)) {
        payloadData = {
          ...inputField,
          soldDetails: {
            ...soldData,
          },
        };
      }

      Object.keys(payloadData).forEach((key) => {
        if (key === "soldDetails" && isValidElement(payloadData?.soldDetails)) {
          Object.keys(payloadData?.soldDetails).forEach((soldKey) => {
            if (["customerPhoto", "aadhaarPhoto"].includes(soldKey)) {
              payloadData.soldDetails[soldKey].forEach((file, index) => {
                if (typeof file !== "string")
                  formData.append(`soldDetails[${soldKey}]`, file);
              });
            } else {
              formData.append(
                `soldDetails[${soldKey}]`,
                payloadData?.soldDetails[soldKey]
              );
            }
          });
        } else if (
          key === "vehicleImages" &&
          Array.isArray(payloadData.vehicleImages)
        ) {
          // Handle `vehicleImages` as an array of files
          payloadData.vehicleImages.forEach((file, index) => {
            if (typeof file !== "string")
              formData.append(`vehicleImages`, file);
          });
          const existingImage = payloadData.vehicleImages.filter(
            (file, index) => {
              return typeof file === "string";
            }
          );
          formData.append(`existingvehicleImages`, existingImage);
        } else if (key === "rcBook" && Array.isArray(payloadData.rcBook)) {
          // Handle `vehicleImages` as an array of files
          if (payloadData?.rcBook && payloadData?.rcBook.length > 0) {
            payloadData?.rcBook.forEach((file, index) => {
              if (typeof file !== "string") formData.append(`rcBook`, file);
            });
          }
          const existingImage = payloadData.rcBook.filter((file, index) => {
            return typeof file === "string";
          });
          formData.append(`existingRCImages`, existingImage);
        } else {
          formData.append(key, payloadData[key]);
        }
      });

      let data = { payLoad: formData, authorization: authorization };
      if (isEdit && isValidString(editData?.id)) {
        data.productId = editData?.id;
      }

      dispatch(
        getProductAction(
          data,
          isEdit ? "EDIT_VEHICLE_DATA" : "ADD_VEHICLE_DETAILS"
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputField, isEdit, soldData, isSold]);

    useImperativeHandle(ref, () => ({
      formSubmit,
    }));

    const handleInputChange = useCallback(
      ({ target: { value } }, key, type, maxLength, setKeyValue) => {
        if (!isValidElement(value)) return;

        const isValidNumber =
          type !== "number" || value === "" || !isNaN(value);
        const isWithinMaxLength =
          !isValidElement(maxLength) || value?.length <= maxLength;

        if (isValidNumber && isWithinMaxLength) {
          const updateState = (setState) =>
            setState((prevValues) => ({ ...prevValues, [key]: value }));

          if (setKeyValue === "INPUT_DATA") {
            updateState(setInputField);
          } else if (setKeyValue === "SOLD_DATA") {
            updateState(setSoldData);
          }
        }
      },
      []
    );
    const validateMandatoryFields = useCallback(() => {
      const validateField = (item, fields) => {
        const value = fields[item.input_key];
        let checkMinLength = value?.length >= item.minLength;
        if (item.type === "number" || typeof value === "number") {
          checkMinLength = value.toString()?.length >= item.minLength;
          return value !== "" && !isNaN(value) && value > 0 && checkMinLength;
        }
        return checkMinLength;
      };

      const fieldsToValidate = [...inputData, ...(isSold ? soldDetails : [])];
      const combinedFields =
        isSold && isValidElement(soldData)
          ? { ...inputField, ...soldData }
          : inputField;
      return fieldsToValidate.every((item) => {
        if (item.mandatory) {
          return validateField(item, combinedFields);
        }
        return true;
      });
    }, [inputField, isSold, soldData]);

    useEffect(() => {
      const allValid = validateMandatoryFields();
      setButtonActive && setButtonActive(!allValid);
    }, [validateMandatoryFields, setButtonActive]);

    const renderInputFields = useCallback(
      (getData, setKeyValue) => {
        const inputState = setKeyValue === "INPUT_DATA" ? inputField : soldData;
        const setState =
          setKeyValue === "INPUT_DATA" ? setInputField : setSoldData;

        return (
          <>
            {getData?.map((item, index) => {
              return item?.inputType === "IMAGE" ? (
                <div key={index}>
                  <ImageUploadComponent
                    setState={setState}
                    inputKey={item?.input_key}
                    data={inputState}
                    label={item?.label}
                    mandatory={item?.mandatory}
                    key={index}
                  />
                </div>
              ) : (
                <div key={item?.input_key} className="w-full">
                  <InputComponet
                    key={item?.input_key}
                    maxLength={item?.maxLength}
                    value={inputState[item?.input_key]}
                    type={item?.type}
                    mandatory={item?.mandatory}
                    placeholder={item?.label}
                    label={item?.label}
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        item?.input_key,
                        item?.type,
                        item?.maxLength,
                        setKeyValue
                      )
                    }
                  />
                </div>
              );
            })}
          </>
        );
      },

      [handleInputChange, inputField, soldData]
    );

    return (
      <div className="grid grid-cols-1  sm:grid-cols-2  gap-4">
        {isSold && renderInputFields(soldDetails, "SOLD_DATA")}
        {renderInputFields(inputData, "INPUT_DATA")}
      </div>
    );
  }
);

export default React.memo(AddEditFrame);
