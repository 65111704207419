import { call, fork, put, takeLatest } from "redux-saga/effects";
import { PRODUCT_TYPE } from "./productType";
import { toast } from "react-toastify";
import { apicall } from "../axiosConfig/apicall";
import { PRODUCT_NETWORK } from "./productNetwork";
import {
  isValidElement,
  getValidData,
  isValidString,
} from "../utils/productHelper";

function* fetchAuthToken(data) {
  try {
    if (
      data?.isAuthenticated && isValidString(data?.token)
    ) {
      yield put({
        type: PRODUCT_TYPE.PRODUCT_REDUCER_TYPE,
        params: {
          authToken: data?.token,
        },
      });

      toast.success("logged in succesfully");
    } else {
      toast.error("Invalid user credentials");
    }
  } catch (error) {
    toast.error("oops something went wrong");
  }
}

function* fetchProductData(data) {
  try {
    yield put({
      type: PRODUCT_TYPE.PRODUCT_REDUCER_TYPE,
      params: {
        productList: [],
        productLoading: true,
      },
    });
    
    yield put({
      type: PRODUCT_TYPE.PRODUCT_REDUCER_TYPE,
      params: {
        isEnquiryListLoading: true,
      },
    });
    const response = yield apicall(PRODUCT_NETWORK.getProductDetails);

    if (response?.status === 200 && isValidElement(response?.response)) {
      const productListData = getValidData(response?.response);
      yield put({
        type: PRODUCT_TYPE.PRODUCT_REDUCER_TYPE,
        params: {
          productList: productListData,
        },
      });
    }
  } catch (e) {
    toast.error(e);
  } finally {
    yield put({
      type: PRODUCT_TYPE.PRODUCT_REDUCER_TYPE,
      params: {
        productLoading: false,
      },
    });
    
    yield put({
      type: PRODUCT_TYPE.PRODUCT_REDUCER_TYPE,
      params: {
        isEnquiryListLoading: true,
      },
    });
  }
}

function* fetchEnquiryList(data) {
  try {
    yield put({
      type: PRODUCT_TYPE.PRODUCT_REDUCER_TYPE,
      params: {
        enquiryList: [],
        isEnquiryListLoading: true,
      },
    });
    const response = yield apicall(PRODUCT_NETWORK.getEnquiryList,data);

    if (response?.status === 200 && isValidElement(response?.response)) {
      const enquiryListData = getValidData(response?.response);
      yield put({
        type: PRODUCT_TYPE.PRODUCT_REDUCER_TYPE,
        params: {
          enquiryList: enquiryListData,
        },
      });
    }
  } catch (e) {
    toast.error(e);
  } finally {
    yield put({
      type: PRODUCT_TYPE.PRODUCT_REDUCER_TYPE,
      params: {
        isEnquiryListLoading: false,
      },
    });
  }
}

function* addEnquryData(payload) {
  try {
    const response = yield apicall(PRODUCT_NETWORK.postEnquiryList, payload);

    if (
      isValidElement(response) &&
      response.status === 200 &&
      isValidString(response?.response?.message)
    ) {
      toast.success(response?.response?.message);
      yield put({
        type: PRODUCT_TYPE.PRODUCT_REDUCER_TYPE,
        params: {
          isCloseEnquiryModal: true,
        },
      });
    }
  } catch (e) {
    toast.error(e);
  }
}

function* putProductData(payload) {
  try {
    const response = yield apicall(PRODUCT_NETWORK.postProductList, payload);
    if (
      isValidElement(response) &&
      response.status === 200 &&
      isValidString(response?.response?.message)
    ) {
      toast.success(response?.response?.message);
      yield put({
        type: PRODUCT_TYPE.PRODUCT_REDUCER_TYPE,
        params: {
          isAddProductSuccess: true,
        },
      });
    }
  } catch (e) {
    toast.error(e);
  }
}

function* edit_vehicle_data(payload) {
  try {
    const response = yield apicall(PRODUCT_NETWORK.editProductData, payload);

    if (
      isValidElement(response) &&
      response.status === 200 &&
      isValidString(response?.response?.message)
    ) {
      toast.success(response?.response?.message);
      yield fork(fetchProductData);
      yield put({
        type: PRODUCT_TYPE.PRODUCT_REDUCER_TYPE,
        params: {
          isAddProductSuccess: true,
        },
      });
    }
  } catch (e) {
    toast.error("some thing went wrong");
  }
}

function* productWatcherSaga(action) {
  try {
    const apiMap = {
      GET_USER_DETAILS: fetchAuthToken,
      GET_PRODUCT_LIST: fetchProductData,
      GET_ENQUIRY_LIST: fetchEnquiryList,
      ADD_ENQUIRY_DATA: addEnquryData,
      ADD_VEHICLE_DETAILS: putProductData,
      EDIT_VEHICLE_DATA: edit_vehicle_data,
    };
    const apiSaga = apiMap[action.meta];
    if (apiSaga) {
      yield call(apiSaga, action?.payload);
    }
  } catch (e) {
    toast.error("some thing went wrong");
  }
}

export default function* productSaga() {
  yield takeLatest(PRODUCT_TYPE.PRODUCT_SAGA_TYPE, productWatcherSaga);
}
