import React, { useState } from "react";
import Button from "./Button";
import { isValidString } from "../utils/productHelper";
import defaultImg from "../assests/images/emptyImg.jpg";
import { safeText } from "../utils/productHelper";

function ListComponent({ listData, onItemPress }) {
  const isSoldOut = listData?.soldDetails; // Check if soldDetails exists
  const images = listData?.vehicleImages || [defaultImg]; // Ensure an array of images
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false); // State for zoom feature

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleImageClick = () => {
    setIsZoomed(true); // Open the zoomed view
  };

  const handleCloseZoom = () => {
    setIsZoomed(false); // Close the zoomed view
  };
  const handleNothing = () => {
    setIsZoomed(false); // Close the zoomed view
  };

  return (
    <div
      className={`bg-white border-2 gap-2 p-2 rounded-xl ${
        isSoldOut ? "opacity-50" : ""
      } relative`}
    >
      {isSoldOut && (
        <div className="absolute top-2 left-2 bg-red-600 text-white p-3 rounded-md shadow-lg font-semibold text-lg z-10">
          Sold Out
        </div>
      )}
      <div className="rounded-xl w-full h-[160px] overflow-hidden mb-3 relative">
        <img
          className="w-full h-full object-cover cursor-pointer"
          alt="list-img"
          src={
            isValidString(images[currentImageIndex])
              ? images[currentImageIndex]
              : defaultImg
          }
          onClick={!isSoldOut ?  handleImageClick : handleNothing} // Trigger zoom on click
        />
        {/* Arrows for navigation */}
        {images.length > 1 && (
          <>
            <button
              onClick={handlePrevImage}
              className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full shadow-lg"
            >
              &#8249;
            </button>
            <button
              onClick={handleNextImage}
              className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full shadow-lg"
            >
              &#8250;
            </button>
          </>
        )}
      </div>

      {/* Zoomed image modal */}
      {isZoomed && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative w-[70%] h-[90%] bg-transparent rounded-lg overflow-hidden shadow-lg">
            <button
              onClick={handleCloseZoom}
              className="absolute top-2 right-2 text-white bg-red-600 p-2 rounded-full z-10"
            >
              ✕
            </button>
            <img
              src={
                isValidString(images[currentImageIndex])
                  ? images[currentImageIndex]
                  : defaultImg
              }
              alt="Zoomed"
              className="w-full h-full object-contain"
            />
            {/* Navigation Arrows */}
            {images.length > 1 && (
              <>
                <button
                  onClick={handlePrevImage}
                  className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full z-10"
                >
                  &#8249;
                </button>
                <button
                  onClick={handleNextImage}
                  className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full z-10"
                >
                  &#8250;
                </button>
              </>
            )}
          </div>
        </div>
      )}
      <div className="flex flex-1 flex-col">
        <div className="flex flex-row justify-between items-center mb-3">
          <div className="flex flex-row items-center">
            <span className="material-symbols-outlined">currency_rupee</span>
            <h6 className="text-[20px] font-semibold text-stone-500">
              {listData.price}
            </h6>
          </div>
          <Button
            styleClass="py-[5px] px-3"
            handlePress={onItemPress}
            label={"Enquire"}
            disabled={isSoldOut} // Disable button if sold out
          />
        </div>
        <div className="flex-1 grid grid-cols-3 gap-1">
          {/* Details */}
          <div>
            <label className="text-[12px] font-semibold text-black">
              Reg Number
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {safeText(listData?.registrationNumber)}
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">
              Model
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {safeText(listData?.model)}
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">
              Km Driven
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {safeText(listData?.kmDriven)}
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">Fc</label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {safeText(listData?.fc)}
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">
              Insurance
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {safeText(listData?.insurance)}
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">Tax</label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {safeText(listData?.tax)}
            </h6>
          </div>

          <div>
            <label className="text-[12px] font-semibold text-black">Make</label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {safeText(listData?.make)}
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">
              Passing
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {safeText(listData?.passing)}
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">
              Emission Standard
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {safeText(listData?.emissionStandard)}
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">feet</label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {safeText(listData?.feet)}
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">
              OwnerShip
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {safeText(listData?.ownership)}
            </h6>
          </div>
          <div>
            <label className="text-[12px] font-semibold text-black">
              Body Type
            </label>
            <h6 className="text-[12px] font-semibold text-stone-500">
              {safeText(listData?.bodyType)}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ListComponent);
