import axios from "axios";

const networkInterCeptors = ({
  urlPath,
  body = null,
  method = "GET",
  timeout = 10000,
  headers = {},
  data = null,
}) => {
  const axiosInstance = axios.create({
    baseURL: `https://transport-business-backend.vercel.app/`,
    timeout,
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const formattedError = {
        status: error.response?.status || 500,
        message:
          error.response?.data?.message || "An unexpected error occurred",
        details: error.response?.data?.errors || null,
        url: error.config?.url,
      };

      return Promise.reject(formattedError);
    }
  );

  return axiosInstance({
    url: urlPath,
    method,
    data: body,
  });
};

export default networkInterCeptors;
