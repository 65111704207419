import { createSelector } from "reselect";
import { isValidString } from "../utils/productHelper";
import {
  filterAvailableProducts,
  getEnquiryWithProduct,
} from "../utils/productHelper";

const productState = (state) => state?.productReducer;

export const userLoggedInnSelector = createSelector([productState], (items) => {
  return {
    isUserLoggedInn: isValidString(items?.authToken),
    authorization:items?.authToken
  };
});

export const productListData = createSelector([productState], (items) => {
  return {
    productList: items?.productList,
    AvailableProducts: items?.productList,
    productLoading: items?.productLoading,
    isAddProductSuccess: items?.isAddProductSuccess,
  };
});

export const enquiryList = createSelector([productState], (items) => {
  return {
    enquires: getEnquiryWithProduct(items?.enquiryList, items?.productList),
    isEnquiryListLoading: items?.isEnquiryListLoading,
    isCloseEnquiryModal: items?.isCloseEnquiryModal,
  };
});
