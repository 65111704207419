import React, { useCallback, useMemo, useState } from "react";
import {
  isArrayNonEmpty,
  isValidString,
  safeText,
} from "../utils/productHelper";

function FilterSearch({
  filteredData,
  setSelectedCategories,
  selectedCategories,
}) {
  const [searchText, setSearchText] = useState("");

  const filterItems = useCallback(
    (item) => {
      if (isValidString(item?.id)) {
        setSelectedCategories((prevItems) => {
          if (prevItems.includes(item.id)) {
            return prevItems.filter((categoryId) => categoryId !== item.id);
          } else {
            return [...prevItems, item.id];
          }
        });
      }
    },
    [setSelectedCategories]
  );

  const filteredItems = useMemo(() => {
    return filteredData?.filter((item) => {
      // Convert item.model to a safe string
      const model = (item?.model !== undefined && item?.model !== null) 
        ? String(item?.model).toLowerCase() 
        : "";
    
      // Convert item.registrationNumber to a safe string
      const registrationNumber = (item?.registrationNumber !== undefined && item?.registrationNumber !== null) 
        ? String(item?.registrationNumber).toLowerCase() 
        : "";
    
      // Convert searchText to a safe string
      const search = (searchText !== undefined && searchText !== null) 
        ? String(searchText).toLowerCase() 
        : "";
    
      // Perform the search
      return (
        model.includes(search) || registrationNumber.includes(search)
      );
    });
  }, [filteredData, searchText]);
  const uniqueItems = filteredItems.filter((value, index, self) => 
    index === self.findIndex((t) => (
      t.make === value.make && t.model === value.model
    ))
  );
  return (
    <div>
      <div className="flex items-center w-full max-w-md p-1 px-2 bg-gray-100 rounded-lg shadow-sm mb-2">
        <span className="material-symbols-outlined text-gray-500">Search </span>
        <input
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e?.target?.value)}
          placeholder="Search Model, Reg Number..."
          className="flex-1 px-2 py-1 text-gray-700 bg-transparent border-none outline-none"
        />
      </div>

      <div className="w-full max-h-[250px] overflow-y-auto">
        {isArrayNonEmpty(filteredItems) ? (
          uniqueItems?.map((item) => (
            <div
              key={item.id}
              className="flex justify-between p-2 bg-white border-b-2"
            >
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(item?.id)}
                  onChange={() => filterItems(item)}
                  className="form-checkbox wtext-blue-600"
                />
                <span className="text-gray-800">{safeText(item?.model)}</span>
              </div>

              <span className="text-gray-500">
                {safeText(item?.make)}
              </span>
              {/* <span className="text-gray-500">
                {safeText(item?.model)}
              </span> */}
            </div>
          ))
        ) : (
          <p className="text-gray-500">No items found.</p>
        )}
      </div>
    </div>
  );
}

export default FilterSearch;
