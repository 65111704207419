import React from "react";

const Modal = ({ isOpen, closeModal, children, styles }) => {
  if (!isOpen) return null; // If the modal is closed, don't render it

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 p-3 ">
      <div className={`bg-white z-50 relative rounded-lg shadow-lg ${styles}`}>
        <div className="flex justify-between items-center">{children}</div>
      </div>
      <div
        onClick={() => closeModal()}
        className="bg-black bg-opacity-50 absolute inset-0 "
      ></div>
    </div>
  );
};

export default React.memo(Modal);
