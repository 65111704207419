import React, { useCallback } from "react";
import Button from "./Button";
import { isValidElement } from "../utils/productHelper";

import { toast } from "react-toastify";
// Helper function to check if a string is valid
const isValidString = (value) => typeof value === "string" && value.trim().length > 0;

function ImageUploadComponent({ label, mandatory, inputKey, data, setState }) {
  const getImagePreview = (file) => {
    if (file instanceof File) {
      return URL.createObjectURL(file); // Generate object URL for File objects
    }
    return file; // Use the string URL if already provided
  };

  const handleFileChange = useCallback(
    (event, key) => {
      const files = event?.target?.files;

      if (files && files.length > 0) {
        const validFiles = Array.from(files).filter((file) => {
          const isValidFileType = file.type.startsWith("image/");
          const isValidSize = file.size <= 5 * 1024 * 1024; // 5 MB max size

          if (!isValidFileType) {
            toast.error("Please upload only image files.");
            return false;
          }

          if (!isValidSize) {
            toast.error("File size should be less than 5 MB (${file.name}).");
            return false;
          }

          return true;
        });

        // Append the new valid files to the current state
        setState((prevValues) => ({
          ...prevValues,
          [key]: [...(prevValues[key] || []), ...validFiles],
        }));
      }
    },
    [setState]
  );

  const handleRemoveImage = useCallback(
    (key, indexToRemove) => {
      setState((prevValues) => ({
        ...prevValues,
        [key]: prevValues[key].filter((_, index) => index !== indexToRemove),
      }));
    },
    [setState]
  );

  return (
    <div key={inputKey} className="w-full">
      <label className="block text-sm font-medium text-gray-700 mb-2">
        {label}
        {mandatory && <span className="text-red-500 ml-1">*</span>}
      </label>
      <div className="flex flex-wrap gap-4">
        {data[inputKey]?.length > 0 &&
          data[inputKey].map((file, index) => (
            <div key={index} className="relative w-32 h-32 border border-gray-300 rounded overflow-hidden">
              <img
                src={getImagePreview(file)}
                alt={`Uploaded ${index + 1}`}
                className="w-full h-full object-cover"
              />
              <button
                type="button"
                className="absolute top-0 right-0 p-1 bg-red-500 text-white text-xs rounded-full"
                onClick={() => handleRemoveImage(inputKey, index)}
              >
                ✕
              </button>
            </div>
          ))}

        <label
          htmlFor={`file-input-${inputKey}`}
          className="relative block w-32 h-32 border-2 border-dashed rounded-lg overflow-hidden cursor-pointer focus:outline-none flex items-center justify-center text-gray-500"
        >
          <span className="material-symbols-outlined text-[35px]">add_photo_alternate</span>
          <input
            type="file"
            id={`file-input-${inputKey}`}
            accept="image/*"
            multiple // Allow multiple files to be selected
            className="hidden"
            onChange={(e) => handleFileChange(e, inputKey)}
          />
        </label>
      </div>
    </div>
  );
}

export default ImageUploadComponent;
