import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import VehicleImageCarousel from "./VehicleImageCarousel";
import TableComponent from "../components/TableComponent";
import Button from "../components/Button";
import AddEditFrame from "./AddEditFrame";
import ToggleSwitch from "../components/ToggleSwitch";
import {
  getProductAction,
  productReducerAction,
} from "../reduxSetup/productAction";
import { useSelector, useDispatch } from "react-redux";
import { productListData } from "../reduxSetup/productSelector";
import {
  isArrayNonEmpty,
  isValidElement,
  isValidString,
  safeText,
} from "../utils/productHelper";
import img from "../assests/images/emptyImg.jpg";

function ManageProducts() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [markAsSold, setMarkAsSold] = useState(false);
  const dispatch = useDispatch();
  const [btnActive, setBtnActive] = useState(true);
  const [editData, setEditData] = useState(null);
  const editItem = useRef(null);
  const [filterText, setFilterText] = useState("");
  const [statusSelected, setStatusSelected] = useState("SelectItem");
  const [soldDetails, setSoldDetails] = useState(null);
  const [toggleDisabled, setToggleDisabled] = useState(false);

  const { productList, productLoading, isAddProductSuccess } =
    useSelector(productListData);

  const handleCloseShowModal = useCallback(() => {
    setSoldDetails(null);
  }, []);

  const handleEditOpen = useCallback((data) => {
    if (isValidElement(data)) {
      setEditData(data);
      setModalOpen(true);
      if (isValidElement(data?.soldDetails)) {
        setMarkAsSold(true);
        setToggleDisabled(true);
      }
    }
  }, []);

  const filteredItems = useMemo(() => {
    let filteredData = [];
    if (isArrayNonEmpty(productList)) {
      filteredData = productList;

      if (isValidString(filterText) && filterText?.length > 3) {
        const normalizedFilterText = filterText.toString().toLowerCase(); // Normalize filterText to avoid errors

        filteredData = productList.filter((item) => {
          const model = item?.model ? item?.model.toString().toLowerCase() : ""; // Safely convert model to string
          const registrationNumber = item?.registrationNumber
            ? item?.registrationNumber.toString().toLowerCase()
            : ""; // Safely convert registrationNumber to string

          return (
            model.includes(normalizedFilterText) ||
            registrationNumber.includes(normalizedFilterText)
          );
        });
      }
    }

    return filteredData;
  }, [productList, filterText]);

  const filterdByStatus = useMemo(() => {
    let statusFiltered = filteredItems;
    if (statusSelected === "Sold") {
      statusFiltered = filteredItems?.filter((item) => {
        return isValidElement(item?.soldDetails);
      });
    } else if (statusSelected === "Available") {
      statusFiltered = filteredItems?.filter((item) => {
        return !isValidElement(item?.soldDetails);
      });
    }

    return statusFiltered;
  }, [filteredItems, statusSelected]);

  const hadleShowSoldDetails = useCallback((data) => {
    if (isValidElement(data?.soldDetails)) {
      setSoldDetails(data?.soldDetails);
    }
  }, []);

  useEffect(() => {
    if (isAddProductSuccess) {
      setModalOpen(false);
      dispatch(productReducerAction({ isAddProductSuccess: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddProductSuccess]);

  const HeaderConstants = useMemo(() => {
    return [
      {
        Title: "Registration Number",
        flexWidthClass: "w-[180px]",
        customRender: false,
        keyToMap: "registrationNumber",
      },
      {
        Title: "Vehicle Model",
        flexWidthClass: "w-[150px]",
        customRender: false,
        keyToMap: "model",
      },
      {
        Title: "Vehicle Image",
        flexWidthClass: "w-[150px]",
        customRender: true,
        keyToMap: "vehicleImages",
        renderItem: (value) => {
          return <VehicleImageCarousel images={value?.vehicleImages} />;
        },
      },
      {
        Title: "Price",
        flexWidthClass: "w-1/6",
        customRender: true,
        keyToMap: "price",
        renderItem: (value) => (
          <p className="text-sm text-slate-500 flex row items-center">
            <span className="material-symbols-outlined text-sm">
              currency_rupee
            </span>
            {safeText(value?.price)}
          </p>
        ),
      },

      {
        Title: "Make",
        flexWidthClass: "w-1/6",
        customRender: false,
        keyToMap: "make",
      },
      {
        Title: "Actions",
        flexWidthClass: "w-1/6",
        customRender: true,
        renderItem: (value) => (
          <div className="flex row gap-3 items-center">
            <Button
              iconName="edit"
              handlePress={() => handleEditOpen(value)}
              styleClass="w-[30px] flex justify-center items-center border-inherit h-[30px]"
              iconBtn={true}
            />
            {isValidElement(value?.soldDetails) && (
              <button
                onClick={() => hadleShowSoldDetails(value)}
                className="bg-red-300 p-1 border-2 border-red-600 rounded-lg w-[50px] text-sm font-semibold text-red-600"
              >
                Sold
              </button>
            )}
          </div>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getProductAction(null, "GET_PRODUCT_LIST"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
    setMarkAsSold(false);
    setToggleDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hadleAddPageNavigation = useCallback(() => {
    navigate("/AddProducts");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCollapseItem = useCallback((item) => {
    return (
      <div className="grid grid-cols-4  gap-4">
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">
            Emission Standard:
          </label>
          <p className="text-sm font-medium text-slate-500 ">
            {safeText(item?.emissionStandard)}
          </p>
        </div>

        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">Fc:</label>
          <p className="text-sm font-medium text-slate-500 ">
            {safeText(item?.fc)}
          </p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">Passing:</label>
          <p className="text-sm font-medium text-slate-500 ">
            {safeText(item?.passing)}
          </p>
        </div>

        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">Insurance:</label>
          <p className="text-sm font-medium text-slate-500 ">
            {safeText(item?.insurance)}
          </p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">Feet:</label>
          <p className="text-sm font-medium text-slate-500 ">
            {safeText(item?.feet)}
          </p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">Km Driven:</label>
          <p className="text-sm font-medium text-slate-500 ">
            {safeText(item?.kmDriven)}
          </p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">OwnerShip:</label>
          <p className="text-sm font-medium text-slate-500 ">
            {safeText(item?.ownership)}
          </p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">Tax:</label>
          <p className="text-sm font-medium text-slate-500 ">
            {safeText(item?.tax)}
          </p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-xs font-semibold capitalize">Body type:</label>
          <p className="text-sm font-medium text-slate-500 ">
            {safeText(item?.bodyType)}
          </p>
        </div>
        <div className="flex gap-x-2 items-center">
          <label className="text-sm font-medium text-black-500 capitalize ">
            Rc Book :
          </label>
          <VehicleImageCarousel images={item?.rcBook} />
        </div>
      </div>
    );
  }, []);

  const EditItem = useCallback(() => {
    if (editItem?.current) {
      editItem?.current?.formSubmit();
      closeModal();
    }
  }, [closeModal]);

  const handleToggle = useCallback(() => {
    setMarkAsSold(!markAsSold);
  }, [markAsSold]);

  const handleSelectToggle = useCallback((e) => {
    if (isValidElement(e?.target?.value)) {
      setStatusSelected(e?.target?.value);
    }
  }, []);

  const renderSelelectFilter = useMemo(() => {
    return (
      <div className="flex flex-col items-start ">
        <select
          id="statusFilter"
          value={statusSelected}
          onChange={handleSelectToggle}
          className="block w-48 px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="SelectItem">Select Status</option>
          <option value="Available">Available</option>
          <option value="Sold">Sold</option>
        </select>
      </div>
    );
  }, [handleSelectToggle, statusSelected]);

  const renderTableComponent = useMemo(() => {
    return (
      <TableComponent
        customFooterItem={renderSelelectFilter}
        loader={productLoading}
        collapseable={true}
        headerDetails={HeaderConstants}
        data={filterdByStatus}
        renderCollapse={(item) => renderCollapseItem(item)}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HeaderConstants, filterdByStatus, productLoading, renderSelelectFilter]);

  const renderFilterSearch = useMemo(() => {
    return (
      <div className="flex items-center w-full max-w-md p-1 px-2 bg-gray-100 rounded-lg shadow-sm">
        <span className="material-symbols-outlined text-gray-500">Search </span>
        <input
          type="text"
          value={filterText}
          onChange={(e) => setFilterText(e?.target?.value)}
          placeholder="Search Model, Reg Number..."
          className="flex-1 px-2 py-1 text-gray-700 bg-transparent border-none outline-none"
        />
      </div>
    );
  }, [filterText]);

  const renderModal = useMemo(() => {
    return (
      <Modal
        styles="w-full max-w-[800px]"
        closeModal={closeModal}
        isOpen={modalOpen}
      >
        <div className="w-full">
          <div className="flex-row flex p-5 justify-between items-center">
            <h3 className="font-medium text-xl ">Edit Item</h3>

            <Button
              styleNone={true}
              iconName={"close"}
              handlePress={closeModal}
              iconBtn={true}
            />
          </div>
          <div className="overflow-y-auto max-h-[530px] pb-5  px-5  flex-1">
            <AddEditFrame
              ref={editItem}
              editData={editData}
              isEdit={true}
              setButtonActive={setBtnActive}
              isSold={markAsSold}
            />
          </div>
          <div className="px-5 py-3 flex justify-between items-center border-t-2">
            <div className="flex  items-center gap-4">
              <label className="text-sm font-medium text-gray-700">
                Mark as Sold
              </label>
              <ToggleSwitch
                disabled={toggleDisabled}
                onToggle={handleToggle}
                isOn={markAsSold}
              />
            </div>

            <Button
              disabled={btnActive}
              label={"Update Vehicle"}
              handlePress={EditItem}
              styleClass="w-[160px] py-2 px-4"
            />
          </div>
        </div>
      </Modal>
    );
  }, [
    closeModal,
    modalOpen,
    editData,
    markAsSold,
    handleToggle,
    btnActive,
    EditItem,
    toggleDisabled,
  ]);

  const renderShowSoldModal = useMemo(() => {
    return (
      <Modal
        styles="w-full max-w-[600px]"
        closeModal={handleCloseShowModal}
        isOpen={isValidElement(soldDetails)}
      >
        <div className="w-full">
          <div className="flex-row flex p-3 justify-between items-center">
            <h3 className="font-medium text-xl ">Sold Details</h3>
            <Button
              styleNone={true}
              iconName={"close"}
              handlePress={handleCloseShowModal}
              iconBtn={true}
            />
          </div>
          <div className="px-3 pb-3 grid grid-cols-2 gap-5">
            <div>
              <div className="mb-3 flex row items-center gap-2">
                <label className="text-md font-medium text-black-500 capitalize ">
                  name :
                </label>
                <p className="text-slate-700 text-md">
                  {safeText(soldDetails?.name)}
                </p>
              </div>
              <div className="mb-3 flex row items-center gap-2">
                <label className="text-md font-medium text-black-500 capitalize ">
                  date :
                </label>
                <p className="text-slate-700 text-md">
                  {safeText(soldDetails?.date)}
                </p>
              </div>
              <div className="mb-3 flex row items-center gap-2">
                <label className="text-md font-medium text-black-500 capitalize ">
                  Chasis Number :
                </label>
                <p className="text-slate-700 text-md">
                  {safeText(soldDetails?.chasisNumber)}
                </p>
              </div>
              <div className="mb-3 flex row items-center gap-2">
                <label className="text-md font-medium text-black-500 capitalize ">
                  Engine Number :
                </label>
                <p className="text-slate-700 text-md">
                  {safeText(soldDetails?.engineNumber)}
                </p>
              </div>
              <div className="mb-3 flex row items-center gap-2">
                <label className="text-md font-medium text-black-500 capitalize ">
                  Customer Phone :
                </label>
                <p className="text-slate-700 text-md">
                  {safeText(soldDetails?.mobileNumber)}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3">
              <div>
                <label className="text-sm font-medium text-black-500 capitalize ">
                  AAdhar Photo:
                </label>
                <img
                  alt="image-key"
                  src={
                    soldDetails?.aadhaarPhoto &&
                    soldDetails?.aadhaarPhoto.length > 0
                      ? soldDetails?.aadhaarPhoto[0]
                      : img
                  }
                  className="w-[100px] h-[60px] rounded-lg"
                ></img>
              </div>

              <div>
                <label className="text-sm font-medium text-black-500 capitalize ">
                  Customer Photo:
                </label>
                <img
                  alt="image-key"
                  src={
                    soldDetails?.customerPhoto &&
                    soldDetails?.customerPhoto.length > 0
                      ? soldDetails?.customerPhoto[0]
                      : img
                  }
                  className="w-[100px] h-[60px] rounded-lg"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soldDetails]);

  const renderPageHeader = useMemo(() => {
    return (
      <div className="flex flex-col px-3 pt-3 sm:px-0 sm:pt-0  md:flex-row justify-between gap-2 md:gap-5 items-center mb-4">
        <h3 className="font-medium text-2xl">Manage products</h3>
        <div className="flex flex-col sm:flex-row gap-5 items-center w-full flex-1 justify-end">
          {renderFilterSearch}

          <Button
            handlePress={hadleAddPageNavigation}
            styleClass="w-[160px] py-2 px-4"
            label="Add Product"
          />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText]);

  return (
    <div className="p-0 sm:p-2 lg:p-10 flex flex-col max-w-screen-lg h-full w-full m-auto">
      {renderPageHeader}
      {renderTableComponent}
      {renderModal}
      {renderShowSoldModal}
    </div>
  );
}

export default React.memo(ManageProducts);
