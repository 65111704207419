import React, { useCallback, useMemo } from "react";
import { navigationConstants } from "../utils/ProductConstants";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userLoggedInnSelector } from "../reduxSetup/productSelector";
import { productReducerAction } from "../reduxSetup/productAction";
import { toast } from "react-toastify";

function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isUserLoggedInn } = useSelector(userLoggedInnSelector);

  const isActive = useCallback(
    (path) => {
      return location?.pathname === path;
    },
    [location]
  );
  const handleIconClick = useCallback((navigation) => {
    navigate(navigation);
    // eslint-disable-next-line
  }, []);

  const handleLogOut = useCallback(() => {
    if (isUserLoggedInn) {
      dispatch(productReducerAction({ authToken: null }));
      navigate('/Home');

      toast.success("User Logged Out");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedInn]);

  const renderNavigationItems = useMemo(() => {
    return (
      <>
        {navigationConstants?.map((item, index) => {
          return (
            <button
              key={index}
              onClick={() => handleIconClick(item?.navigation)}
              className={`${
                isActive(item?.navigation) ? "bg-[#f8fafc5e]" : "bg-transparent	"
              } w-[40px] h-[40px] flex justify-center items-center rounded-[5px]`}
            >
              <span className="text-[24px] font-light text-white material-symbols-outlined">
                {item?.iconName}
              </span>
            </button>
          );
        })}

        {isUserLoggedInn && (
          <button
            onClick={() => handleIconClick("/ViewEnquires")}
            className={`${
              isActive("/ViewEnquires") ? "bg-[#f8fafc5e]" : "bg-transparent	"
            } w-[40px] h-[40px] flex justify-center items-center rounded-[5px]`}
          >
            <span className="text-[24px] font-light text-white material-symbols-outlined">
              headphones
            </span>
          </button>
        )}
      </>
    );
  }, [handleIconClick, isActive, isUserLoggedInn]);
  return (
    <div
      style={{
        flex: 1,
        backgroundColor: "#18181b",
        maxWidth: 55,
        padding: 10,
        position: "sticky",
        top: 0,
        display: "flex",
        flexDirection: "column",
        gap: 15,
        alignItems: "center",
      }}
    >
      <div className="flex-1 flex flex-col gap-2">{renderNavigationItems}</div>
      <div>
        {isUserLoggedInn && (
          <button
            onClick={handleLogOut}
            className={`w-[40px] h-[40px] flex justify-center items-center rounded-[5px]`}
          >
            <span className="text-[20px] font-light text-white material-symbols-outlined">
              logout
            </span>
          </button>
        )}
      </div>
    </div>
  );
}

export default React.memo(SideBar);
