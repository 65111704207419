export const PRODUCT_NETWORK = {
  getProductDetails: () => {
    return {
      method: "GET",
      urlPath: "vehicles",
      headers: {
        "Content-Type": "application/json",
      },
    };
  },

  getEnquiryList: (data) => {
    return {
      method: "GET",
      urlPath: "queries",
      headers: {
        "Content-Type": "application/json",
        "authorization":`Bearer ${data?.authorization}`
      },
    };
  },

  postEnquiryList: (data) => {
    return {
      method: "POST",
      urlPath: "queries",
      body: data,
      headers: {
        "Content-Type": "application/json",
      },
    };
  },

  postProductList: (data) => {
    
    return {
      method: "POST",
      urlPath: "vehicles",
      body: data?.payLoad,
      headers: {
        "Content-Type": "multipart/form-data",
        "authorization":`Bearer ${data?.authorization}`
      },
    };
  },

  editProductData: (payload) => {
    return {
      method: "PUT",
      urlPath: `vehicles/${payload?.productId}`,
      body: payload?.payLoad,
      headers: {
        "Content-Type": "multipart/form-data",
        "authorization":`Bearer ${payload?.authorization}`
      },
    };
  },
};
